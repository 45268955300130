import * as React from "react"
import Layout from '../components/layout'
import PinkFullCircle from '../images/pink-full-circle.svg'
import YellowFullCircle from '../images/yellow-full-circle.svg'
import {Link} from 'gatsby'
import Logo from '../images/logos/5thStrand-Cutout-White.svg'
import ralf from '../images/team/ralf.png'
import jak from '../images/team/jak.png'
import liam from '../images/team/liam.png'
import rosanna from '../images/team/rosanna.png'
import niall from '../images/team/niall.png'
import josh from '../images/team/josh.png'
import globe from '../images/animated/27-globe-outline.gif'
import story from '../images/animated/112-book-morph-outline.gif'



import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';

var ReactRotatingText = require('react-rotating-text');

const Team = () => {
  return (

    <Layout>
      <CustomCursor
      targets={['.link', '.your-css-selector']}
      customClass='custom-cursor'
      dimensions={50}
      fill='#00FFE0'
      opacity={1}
      smoothness={{
        movement: 0.2,
        scale: 0.1,
        opacity: 1,
      }}
      targetOpacity={0.5}
    />
    <main className="cont bg-5th-grey nocursor scroll-smooth	">
      <div className="child sm:text-center flex justify-center items-center relative">
        <div className=" w-5/6">
          <h1 className=" font-bold text-white text-4xl lg:text-8xl font-heading pb-6">
          WHO <span className="text-5th-cyan">? </span>
          WHERE <span className="text-5th-cyan">? </span>
          HOW <span className="text-5th-cyan">?</span>
          </h1>
          <p className="text-xl lg:text-3xl text-white font-heading">Get to know us a bit better.
          </p>
        </div>

        <div className="top-0 left-0 absolute w-24 md:w-36">
          <img className="w-full transition ease-in-out hover:-translate-y-8 duration-300" src={PinkFullCircle}/>
        </div>

        <div className="top-0 right-0 absolute w-24 md:w-36">
          <img className="w-full transition ease-in-out hover:-translate-y-8 duration-300" src={YellowFullCircle}/>
        </div>

        <div className="bottom-10 right-10 absolute ">
          <p className="text-5th-pink">PART OF THE <a target="_blank" href="https://urbanscaleinterventions.com/"><span className="link text-white">USI</span></a> FAMILY</p>
        </div>
      </div>    

      <div className="snap-start snap-proximity sm:text-center md:text-left flex justify-center items-center  relative text-left">
        <div className="w-5/6 mx-auto">
          <div className="text-white font-heading">
            <h2 className="text-4xl lg:text-6xl pb-6 pt-24">OUR TEAM <span className="text-5th-pink">.</span></h2>
            <p className="text-md font-lower text-white md:w-4/6">Our team are not just progammers or designers, but multi-disciplined people-people who want to help make something with meaning, 
              something they can use and something that doesn’t make their PC freeze.</p>
            <div className="py-4 lg:py-12 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8 font-lower text-center">
              <div>
                <img className="mx-auto h-60 pb-4" src={ralf}/>
                <p>Ralf Alwani</p>
                <p className="text-stone-300">Partner</p>
              </div>
              <div>
                <img className="mx-auto h-60 pb-4" src={jak}/>
                <p>Jak Spencer</p>
                <p className="text-stone-300">Partner</p>
              </div>
              <div>
                <img className="mx-auto h-60  pb-4" src={liam}/>
                <p>Liam Wallace</p>
                <p className="text-stone-300">Partner</p>
              </div>
              <div>
                <img className="mx-auto h-60 pb-4" src={rosanna}/>
                <p>Rosanna O'Kane</p>
                <p className="text-stone-300">Designer</p>
              </div>
              <div>
                <img className="mx-auto h-60 pb-4" src={niall}/>
                <p>Niall Forrest</p>
                <p className="text-stone-300">Frontend Developer and UI Designer</p>
              </div>
              <div>
                <img className="mx-auto h-60 pb-4" src={josh}/>
                <p>Josh Watts</p>
                <p className="text-stone-300">Project Manager</p>
              </div>
             
            </div>

          </div>
          <div>

          </div>



          <div className="text-white font-heading grid md:grid-cols-2 py-16 snap-start snap-proximity">
            <div>
            <h2 className="text-4xl lg:text-6xl lg:py-16">WHERE <span className="text-5th-pink">?</span></h2>
            <p className="text-md font-lower text-white">The easiest way to say this is all over the place!
            We’ve always operated as a remote-first company and that gives our team the freedom to be based in quite a few locations.<br/><br/>
            We have digital nomads and permanently based employees working in tandem but if you want to meet face-to-face don't worry we have an office at Portview Trade Centre in Belfast, Northern Ireland for any in-person meetings.</p>             
            </div>
            <div className="mx-auto w-36 lg:w-96 z-10">
              <img className="w-full" src={globe}/>
             </div>
             </div>

            <div className="text-white font-heading grid md:grid-cols-2 py-16 snap-start snap-proximity">
           
            <div className="mx-auto w-36 lg:w-96 z-10">
              <img className="w-full" src={story}/>
             </div>

             <div>
              <h2 className="text-4xl lg:text-6xl py-6 lg:py-16">OUR STORY <span className="text-5th-pink">?</span></h2>
              <p className="text-md font-lower text-white">
                5th Strand was created as an evolution of the digital element of design studio - Urban Scale Interventions.<br/><br/>
                5th Strand combines the experience and core values of our parent company USI with technical expertise and a focus on transforming digital spaces. 
                We know first-hand how change can be a chance for something exciting and new.
              </p>             
            </div>
             </div>
          </div>

        </div>

      <footer className="snap-start snap-proximity p-4 bg-5th-grey sm:p-6 my-6 lg:my-16 mx-auto w-5/6 font-lower">
            <div className="md:flex md:justify-between items-center">
                <div className="mb-6 md:mb-0">
                    <Link to="/" className="flex items-center">
                        <img src={Logo} className="h-36" alt="5th Strand Logo"/>
                    </Link>
                </div>
                <div className="mb-6 md:mb-0">
                  <h1 className="text-white text-2xl sm:text-3xl md:text-4xl leading-relaxed font-bold ">5th Strand<br/><span className='font-normal leading-relaxed'>Believers in Change</span></h1>
                </div>
                <div className="grid grid-cols-2 gap-8 sm:gap-8">
                   
                   
                    <div>
                        <h2 className="mb-6 text-xs font-semibold text-white uppercase">Legal</h2>
                        <ul className="text-white text-xs">
                            <li className="mb-4">
                                <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h2 className="mb-6 font-semibold text-white uppercase text-xs">Contact</h2>
                        <ul className="text-white text-xs">
                            <li className="mb-4">
                                <a href="mailto:studio@5thstrand.com" className="hover:underline text-xs">studio@5thstrand.com</a>
                            </li>
                            <li>
                                <a href="tel:+447754078144" className="hover:underline">Tel: 077 540 78144</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-white sm:text-center ">© 2022 <a href="" className="hover:underline">5th Strand USI</a>. All Rights Reserved.
                    </span>
              
                </div>
        </footer>

    </main>
    </Layout>
  )
}

export default Team

export function Head() {
  return (
    <>
      <title>5th Strand - Team</title>
      <meta name="description" content="5th Strand has a multidiscplined team with years of experience in the digital space." />
        <meta property="og:title" content="5th Strand - Team" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.5thstrand.com/team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    </>  
    )
}